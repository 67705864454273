import React, { FC } from 'react';
import styled from 'styled-components';

import { useTranslation } from '@hooks';

interface Props {
    iconUrl: string;
    label: string;
    count: number;
}

const AlertBadge: FC<Props> = ({ iconUrl, label, count = 0 }) => {
    const t = useTranslation('Remainer');
    return (
        <div className="flex-center gap-2">
            <IconContainer>
                <Icon src={iconUrl} alt={label} />
            </IconContainer>
            <Label>{label}</Label>
            <StatusContainer>
                <CountContainer>{count}</CountContainer>
                <PeopleContainer>{t('People')}</PeopleContainer>
            </StatusContainer>
        </div>
    );
};

const IconContainer = styled.div`
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
    justify-content: center;
    align-items: center;

    border-radius: 6.25rem;
    background-color: #f6f3ec;
`;

const Icon = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    filter: invert(47%) sepia(2%) saturate(2772%) hue-rotate(351deg) brightness(89%) contrast(81%);
`;

const Label = styled.div`
    color: #62554a;
    font-size: 1.25rem;
    font-weight: 600;
`;

const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
`;

const CountContainer = styled.div`
    font-size: 2.5rem;
    font-weight: 700;
    font-variant: tabular-nums;
`;

const PeopleContainer = styled.div`
    font-size: 2rem;
`;

export default AlertBadge;
