import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import RealtimeAlertModal from '../Components/RealtimeAlertModal';
import { deleteNotification } from '@reducer/Notification';

import type { NotificationLog } from '../Components/types';

type HandleAlertClick = (params: { logNum: number; type: string }) => void;

interface NotificationInfo {
    [logNum: number]: NotificationLog;
}

interface Props {
    notification: {
        SOS_ON: NotificationInfo;
    };
}

const RealTimeAlertModalList = ({ notification }: Props) => {
    const dispatch = useDispatch();

    const handleAlertClick: HandleAlertClick = useCallback(({ logNum, type }) => {
        dispatch(deleteNotification({ logNum, type }));
    }, []);

    return useMemo(() => {
        return Object.values(notification).flatMap(notificationInfo =>
            Object.values(notificationInfo).map((data: NotificationLog) => {
                if (data) {
                    const toggleModal = () => {
                        handleAlertClick({ logNum: data.logNum, type: data.interfaceCommandType });
                    };
                    return <RealtimeAlertModal key={data.logNum} data={data} initModal toggleModal={toggleModal} />;
                }
                return null;
            }),
        );
    }, [notification, handleAlertClick]);
};

export default RealTimeAlertModalList;
