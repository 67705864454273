import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getAlertAbbreviation } from './utils';
import { useTranslation } from '@hooks';
import { deleteNotification } from '@reducer/Notification';

import AlarmActionModal from './ActionModal/AlarmActionModal';
import { getAlertType } from '../util/wsUtil';

import { ReactComponent as DangerImg } from '@asset/images/worker-safety/danger_triangle.svg';

import type { NotificationLog } from './types';
import type { BeaconAlertLog } from './ActionModal/types';

interface Props {
    initModal: boolean;
    toggleModal: () => void;
    data: NotificationLog;
}

const MODAL_CLOSE_TIME = 10 * 1000;

const RealtimeAlertModal: FC<Props> = ({ initModal = false, toggleModal = () => {}, data }) => {
    const dispatch = useDispatch();
    const t = useTranslation('Notification');

    const handleModalState = () => {
        dispatch(deleteNotification({ logNum: data.logNum, type: data.interfaceCommandType }));
        toggleModal();
    };

    useEffect(() => {
        const id = setTimeout(() => {
            handleModalState();
        }, MODAL_CLOSE_TIME);
        return () => {
            clearTimeout(id);
        };
    }, []);

    const actionModalData: BeaconAlertLog = {
        categoryCode: data.categoryCode,
        categoryName: data.categoryName,
        alertType: getAlertAbbreviation(data.interfaceCommandType),
        description: '',
        floorId: data.floor,
        floorName: data.floorName,
        lat: data.lat,
        lng: data.lng,
        logNum: data.logNum,
        occurredDate: data.regDate,
        targetName: data.targetName,
        targetNum: data.targetNum,
        geofenceName: data.fcName,
        contact: data.targetProperties.phone_number,
        department: data.targetProperties.team,
    };

    return (
        <AlarmActionModal initModal={initModal} toggleModal={handleModalState} data={actionModalData}>
            <div>
                <div className="d-flex align-items-center font-size-xxlg font-weight-bold gap-1">
                    <DangerImg />
                    <WorkerName>{actionModalData.targetName}</WorkerName>
                    <div>{t('Is In')}</div>
                    <Situation>{t(getAlertType(actionModalData.alertType))}</Situation>
                    <div>{t('Notification Comment')}</div>
                </div>
            </div>
        </AlarmActionModal>
    );
};

const WorkerName = styled.span`
    color: #6a55c0 !important;
`;

const Situation = styled.span`
    color: #ff3358 !important;
`;

export default RealtimeAlertModal;
