import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';

import RemainersLocation from './RemainersLocation';

import { MatchProps } from '@util/type/util';

const RealtimeStatus = ({ match }: MatchProps) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}`} component={RemainersLocation} />
        </ErrorHandleSwitch>
    );
};

export default RealtimeStatus;
