import { reqGet, reqPatch } from './index';

const workerSafetyURL = '/v3/api/ws01';
const kancURL = `${workerSafetyURL}/kanc`;

const ssyencURL = `${workerSafetyURL}/ssyenc`;

// 알림 조치 내용 업데이트 API
export const updateAlertsApi = param => reqPatch(`${kancURL}/alerts`, param);

//// 쌍용건설
// 대시보드 작업자 SOS Low Battery 카운트 및 작업자 목록
export const getDashboardApi = param => reqGet(`${ssyencURL}/dashboard`, param);
