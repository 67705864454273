import moment from 'moment';
import { getLsUserInfo } from '../../../util/common/util';
import envKeys from '../../../environment';
import { createSSOForm } from '../../Layout/AppNav/CustomLink';
import { falsyToDash } from '../util';

const { cryptoIv } = envKeys;

export const getDate = value => {
    return value ? moment(value).format('MM.DD HH:mm:ss') : '-';
};

export const redirectWMSUrl = url => {
    const serviceCode = cryptoIv.split('_')[2];
    const userInfo = getLsUserInfo();
    if (userInfo) {
        createSSOForm(serviceCode, userInfo.oAuthInfo, url);
    }
};

export const sensorHeader = ({ t, headerName, mappedCount, totalCount }) => {
    return `${t(headerName)} (${mappedCount}${t('Count')} ${t('Mapped')} / ${t('Total')} ${totalCount}${t('Count')})`;
};

export const nullToHyphen = value => {
    return value !== 0 && !value ? '-' : value;
};

export const ALERT_ABBREVIATION = {
    SOS_ON: 'Emergency Situation (SOS)',
};

export const getAlertAbbreviation = interfaceCommandType => {
    return falsyToDash(ALERT_ABBREVIATION[interfaceCommandType]);
};
