import moment from 'moment';
import type { AlertTypes } from '../types';
import { useTranslation } from '@hooks';

export const DAY = 'day';

/////////////
/* 비콘상태 */
////////////
export const LOW_BATTERY = 'LB';
export const LOST_SIGNAL = 'LS';

//// //////////
/* 나노 기술원 */
///////////////

export const YES = 'Y';

export const DATE_UNIT = {
    DAYS: 'days',
} as const;

export const SEARCH_DAYS = {
    ONE_DAY: 1,
    SEVEN_DAYS: 7,
    THIRTY_DAYS: 30,
} as const;

export const EXCLUDING_TODAY = 1;

export const INITIAL_TABLE_DATA = {
    totalCount: 0,
    totalPage: 0,
    pageSize: 0,
    page: 1,
    rows: [],
};

export const UNSELECTED = { label: 'All', value: null };

export const CHOOSE = { label: 'Choose', value: null };

export const DEFAULT_SEARCH_OPTION = [
    { label: 'Name', value: 'targetName' },
    { label: 'Institution/Department', value: 'department' },
    { label: 'Phone Number', value: 'contact' },
];

export const ALERT_TYPES = {
    SN: 'Request Rescue',
    AN: 'No Movement',
} as const;

export const getAlertType = (alertType: AlertTypes) => {
    return ALERT_TYPES[alertType] || alertType;
};

export const DAYS = 'days';

export const DATE = {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
} as const;

export const formatDuration = (seconds: number, t: Function) => {
    const duration = moment.duration(seconds, 'seconds');

    const hours = duration.hours();
    const minutes = duration.minutes();
    const secs = duration.seconds();

    const result = [];

    if (!!hours) {
        result.push(`${hours}${t('Hours')}`);
    }

    if (!!minutes) {
        // 분 단위는 시 단위가 있는 경우 0이어도 표시
        result.push(`${minutes}${t('Minutes')}`);
    }

    result.push(`${secs}${t('Seconds')}`);

    return result.join(' ');
};
