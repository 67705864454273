import React, { useState, useEffect, useReducer, createContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync, useTranslation } from '@hooks';
import { setOauthInfo, setLanguage } from '@reducer/UserInfo';
import { fetchOAuthToken } from '@api/login';
import Button from '@components/Button';
import { components } from 'react-select';
import { reducer, initialState, setShowErrorMsg } from './reducer';
import cx from 'classnames';
import LOGO from '@asset/images/worker-safety/company_logo.svg';
import SUB_LOGO from '@asset/images/worker-safety/sub_logo.svg';

// For Further CSS
import { decodeInfo, checkExpire } from '@util/common/util';
import LoginForm from './Components/LoginForm';
import FindForm from './Components/FindForm';
import ChangeForm from './Components/ChangeForm';
import CompleteForm from './Components/CompleteForm';
import useMoreUserInfo from './hooks/useMoreUserInfo';
import styled from 'styled-components';

const Control = ({ children, ...rest }) => (
    <components.Control {...rest}>
        <span
            className="icon-globe"
            style={{ marginLeft: '.5rem', filter: 'invert(1)', transform: "scale('0.8rem')" }}
        />
        {children}
    </components.Control>
);

export const LoginStateContext = createContext();
export const LoginDispatchContext = createContext();

const FormByStep = ({ step }) => {
    switch (step) {
        case 'LOGIN':
            return <LoginForm />;
        case 'FIND':
            return <FindForm />;
        case 'CHANGE':
            return <ChangeForm />;
        case 'COMPLETE':
            return <CompleteForm />;
        default:
            return <LoginForm />;
    }
};

const Login = props => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Login');
    const [state, dispatch] = useReducer(reducer, initialState);
    const { step } = state;
    const [showMobileLogin, setShowMobileLogin] = useState(false);

    const history = useHistory();
    const { oAuthInfo: ssoOAuthInfo } = useParams();

    const { userInfo, lang, smartSafeManagerInfo } = useSelector(state => state.UserInfo);

    const { promise: initialLogin } = useAsync({
        promise: fetchOAuthToken,
        resolve: res => {
            // 2. 로그인 인포가 보내지고 access token이 받아져왔을때
            if (res.scope) {
                storeDispatch(setOauthInfo(res));
                getMoreUserInfo();
            }
        },
        reject: err => {
            dispatch(setShowErrorMsg(true));
        },
    });

    const getMoreUserInfo = useMoreUserInfo();

    // 5. 유저 이름, 유저가 속한 회사 정보가 받아와진 경우 실행
    useEffect(() => {
        if (
            userInfo &&
            userInfo.companyInfo &&
            userInfo.companyInfo.timeZone &&
            smartSafeManagerInfo.menuAuthorization
        ) {
            // 대쉬보드의 홈설정이 되어있는경우
            // if (userInfo.home) {
            //     history.replace(`/dashboards/${userInfo.home}`);
            // }
            // 대쉬보드의 홈설정이 되어있지 않은경우
            // else {
            // history.replace(`/`);
            // }

            // 1. 사용자의 작업장이 셋팅 되있는 경우 - 실시간 작업현황 메뉴로
            // 2. 사용자의 작업장이 설정되있지 않은 경우에는 시스템관리 - 사용자 관리에서 먼저 설정하도록 리다이렉트
            history.replace('/');
        } else {
            history.replace('/login');
        }
    }, [smartSafeManagerInfo]);

    useEffect(() => {
        if (ssoOAuthInfo) {
            try {
                const decodedInfo = decodeInfo(atob(ssoOAuthInfo));
                initialLogin({
                    grant_type: 'refresh_token',
                    refresh_token: decodedInfo.refresh_token,
                });
            } catch (e) {
                console.log(e);
            }
        } else {
            // 추가 인증요청 없이 로그인 페이지 진입 시,
            // localStorage 인증정보가 유효하다면 바로 앱 설정 정보 호출 후 메인 페이지로 이동
            if (checkExpire()) {
                getMoreUserInfo();
            }
        }
    }, [ssoOAuthInfo]);

    // 모바일 창에서 로그인 버튼 클릭시 아이디 패스워드를 입력할 수 있는 창으로 변경
    const handleMobileLoginClick = e => {
        e.preventDefault();
        setShowMobileLogin(!showMobileLogin);
    };

    // 언어 변환
    const handleLangChange = selectedOption => {
        storeDispatch(setLanguage(selectedOption.value));
    };

    return (
        <LoginDispatchContext.Provider value={dispatch}>
            <LoginStateContext.Provider value={state}>
                <div className="page-landering">
                    <div className={`page-landering__cont-wrap ${showMobileLogin && 'login_clicked'}`}>
                        {/* 모바일용 헤더 */}
                        <div className={`lander-mobile-header`}>
                            <div className="mobile-header__wrap"></div>
                        </div>

                        {/* 데스크용 */}
                        <div className={`lander-info`}>
                            <div className="lander-info__wrap">
                                <div className="info--logo">
                                    <LogoWrapper>
                                        <img src={LOGO} alt="logo" className="w-100 h-100" />
                                    </LogoWrapper>
                                </div>

                                <div className="info--txt">
                                    <h2 className="txt__main color-white">
                                        {t('Underground Remainer Safety Management System', 'Menu')}
                                    </h2>
                                    <b className="txt__sub mb-3">
                                        {t(
                                            'Realization of safety and health-free, high-quality, eco-friendly construction sites',
                                            'SmartSafe',
                                        )}
                                    </b>
                                    <p className="txt__desc">
                                        {t(
                                            'The corporate ideology of respect for life was the top priority.',
                                            'SmartSafe',
                                        )}
                                    </p>
                                </div>
                                <div className="info--login-btn">
                                    <Button
                                        className="btn-brand btn-icon btn-animation-move"
                                        iconName="arrow_forward_ios"
                                        onClick={handleMobileLoginClick}
                                    >
                                        {t('Login')}
                                    </Button>
                                </div>
                            </div>
                            <SubLogoWrapper>
                                <img src={SUB_LOGO} alt="sub logo" className="w-100 h-100" />
                            </SubLogoWrapper>
                        </div>

                        <div className="lander-login">
                            <div
                                className={cx(
                                    'lander-login__wrap flx-col',
                                    step === 'CHANGE' && 'flx-full pnt-label-6 label-col-mobile',
                                )}
                            >
                                <FormByStep step={step} />
                            </div>
                        </div>
                    </div>
                    <div className="page-landering__bg" />
                </div>
            </LoginStateContext.Provider>
        </LoginDispatchContext.Provider>
    );
};

const LogoWrapper = styled.div`
    width: 13.375rem;
    height: 2.5rem;
`;

const SubLogoWrapper = styled.div`
    left: 5rem;
    bottom: 3rem;
    position: absolute;
    width: 6.25rem;
    height: 1.585rem;
`;

export default Login;
