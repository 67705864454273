import React, { FC, ReactNode } from 'react';
import { ModalHeader, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { useTranslation, useAppSelector } from '@hooks';
import { Button } from '@components';

import Label from '../Components/Label';

import { makeDateWithBracket } from '../../../util';

import type { Nullable } from '@util/type/util';
import type { BeaconAlertLog } from '../types';

interface Props {
    initModal: boolean;
    toggleModal: Function;
    data: Nullable<BeaconAlertLog>;
    children?: ReactNode;
}

const MAIN_LAYOUT = 'main-layout';
const APP_CONTAINER = 'app-container';

const AlarmActionModal: FC<Props> = ({ initModal = false, toggleModal, data, children }) => {
    const t = useTranslation('Notification');

    const { fullScreen } = useAppSelector(state => state.DashboardFrame);

    return (
        <Modal
            container={document.getElementsByClassName(fullScreen ? MAIN_LAYOUT : APP_CONTAINER)[0] as HTMLElement}
            className="pnt-modal"
            size="xl"
            isOpen={initModal}
            // @ts-ignore
            toggle={toggleModal}
            backdrop="static"
        >
            <ModalHeader>{t('Worker Emergency Notification')}</ModalHeader>
            {!!data && (
                <ModalBody>
                    <div className="flx-col gap-3">
                        {children}

                        <div>
                            <span className="font-weight-bold font-size-lg">{t('Remainer Detail Information')}</span>
                            <div className="border-top border-bottom mt-3">
                                <div className="action-modal-label-container">
                                    <Label name={t('Name')} value={data.targetName} />
                                    <Label name={t('Occurred Date')} value={makeDateWithBracket(data.occurredDate)} />
                                </div>
                                <div className="action-modal-label-container">
                                    <Label name={t('Affiliation')} value={data.department} />
                                    <Label name={t('Phone Number')} value={data.contact} />
                                </div>
                                {/*<div className="action-modal-label-container">*/}
                                {/*<Label*/}
                                {/*    name={t('Location')}*/}
                                {/*    value={falsyToDash(data.geofenceName)}*/}
                                {/*/>*/}
                                {/*</div>*/}
                                {/*<Label name={t('Emergency Situation')} value={t(getAlertType(data.alertType))} />*/}
                            </div>
                        </div>
                    </div>
                </ModalBody>
            )}
            <ModalFooter className="p-2">
                {/*@ts-ignore*/}
                <Button className="btn-trans" onClick={toggleModal}>
                    {t('Close')}
                </Button>
            </ModalFooter>
            <div id="modal-select-container" />
        </Modal>
    );
};

export default AlarmActionModal;
