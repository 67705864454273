import React, { FC } from 'react';
import styled from 'styled-components';

import { falsyToDash } from '../../../util';

import SOS_URL from '../../../../../assets/images/worker-safety/sos.svg';
import LOW_BATTERY_URL from '../../../../../assets/images/worker-safety/low_battery.svg';

interface Props {
    name: string;
    phoneNum: string;
    affiliation: string;
    isSosOn: boolean;
    isBatteryOff: boolean;
}

const RemainerCard: FC<Props> = ({ name, phoneNum, affiliation, isSosOn, isBatteryOff }) => {
    return (
        <RemainerCardContainer>
            <InfoContainer>
                <NameContainer>{name}</NameContainer>
                <div className="d-flex">
                    <PersonalContainer title={phoneNum}>{falsyToDash(phoneNum)}</PersonalContainer>
                    <div className="pnt-divider" />
                    <PersonalContainer title={affiliation}>{falsyToDash(affiliation)}</PersonalContainer>
                </div>
            </InfoContainer>
            <SensorContainer>
                <IconContainer $backgroundColor={isSosOn && '#dc0022'}>
                    <Icon src={SOS_URL} alt="sos" $isAlert={isSosOn} />
                </IconContainer>
                <IconContainer $backgroundColor={isBatteryOff && '#51443b'}>
                    <Icon src={LOW_BATTERY_URL} alt="low battery" $isAlert={isBatteryOff} />
                </IconContainer>
            </SensorContainer>
        </RemainerCardContainer>
    );
};

const TextEllipsis = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const RemainerCardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.75rem;

    background-color: white;
    height: 4rem;
`;

const InfoContainer = styled.div`
    width: calc(100% - 4.5rem);
`;

const NameContainer = styled(TextEllipsis)`
    color: #262549 !important;
    font-size: 1rem;
    font-weight: 700;
`;

const PersonalContainer = styled(TextEllipsis)`
    color: #3e3d5d !important;
    font-weight: 500;
    font-size: 0.75rem;
    max-width: 5.375rem;
`;

const SensorContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    width: 4.5rem;
`;

const IconContainer = styled.div<{ $backgroundColor: string | false }>`
    display: flex;
    width: 1.875rem;
    height: 1.875rem;
    justify-content: center;
    align-items: center;

    border-radius: 6.25rem;
    background-color: ${({ $backgroundColor }) => $backgroundColor || '#f6f5f8'};
`;

const Icon = styled.img<{ $isAlert: boolean }>`
    width: 1rem;
    height: 1rem;
    filter: ${({ $isAlert }) =>
        $isAlert
            ? 'invert(99%) sepia(100%) saturate(0%) hue-rotate(250deg) brightness(108%) contrast(100%)'
            : 'invert(87%) sepia(5%) saturate(515%) hue-rotate(207deg) brightness(111%) contrast(87%)'};
`;

export default RemainerCard;
