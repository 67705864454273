import React, { useRef } from 'react';
import styled from 'styled-components';

import { RotatedImageOverlay } from '@components';
import { useAppSelector } from '@hooks';

import Map from '../../../Components/CustomMap';
import Marker from './CustomMarker';

import type { Tag } from '../types';

const VERTICAL_SPHERE_LOCATION = [
    [37.50681923670426, 127.05253133465094],
    [37.50766268427922, 127.05498036133159],
];

const RemainerMap = () => {
    const mapRef = useRef(null);
    const { floorList } = useAppSelector(state => state.FloorInfo) as any;
    const { tagListByFloor } = useAppSelector(state => state.TagInfo) as any;

    const floorInfo = floorList?.[0] || null;
    const tagList = tagListByFloor[floorInfo?.floorId];

    const handleFitParticularLocation = () => {
        const map = mapRef.current as any;
        if (!map) return;

        const leafletElement = map.leafletElement;

        leafletElement.fitBounds(VERTICAL_SPHERE_LOCATION);
    };

    return (
        <RemainerMapContainer>
            <Map ref={mapRef} tile={false} onLocationClick={handleFitParticularLocation}>
                {floorInfo?.imgURL && (
                    <RotatedImageOverlay
                        onLoad={() => {
                            handleFitParticularLocation();
                        }}
                        key={floorInfo.floorId}
                        url={floorInfo.imgURL}
                        deg={floorInfo.deg}
                        bounds={[
                            [floorInfo.neLat, floorInfo.neLng],
                            [floorInfo.swLat, floorInfo.swLng],
                        ]}
                    />
                )}
                {tagList?.map((tag: Tag) => {
                    const { target, location, state } = tag;
                    return <Marker key={target.targetId} latlng={location.latLng} target={target} state={state} />;
                })}
            </Map>
        </RemainerMapContainer>
    );
};

const RemainerMapContainer = styled.div`
    height: calc(100% - 7.5rem);
`;

export default RemainerMap;
