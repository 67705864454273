import { useEffect, useRef, useState } from 'react';
import type { Nullable } from '@util/type/util';

interface Parameters {
    callback: Function;
    delay: number;
    params?: Nullable<Object>;
    initial?: boolean;
}

type IntervalChange = boolean | undefined;

const useInterval = ({ callback, delay, params, initial = true }: Parameters) => {
    const savedCallback = useRef<Nullable<Function>>(null);
    // intervalState가 false이면 interval이 멈추고 true면 interval이 진행됨
    const [intervalState, setIntervalState] = useState(initial);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // intervalState를 toggle하는 함수
    const toggleDelay = (intervalChange: IntervalChange) => {
        setIntervalState(prevState => {
            if (typeof intervalChange === 'boolean') {
                return intervalChange;
            }
            return !prevState;
        });
    };

    useEffect(() => {
        const interval = () => {
            if (typeof savedCallback.current === 'function') {
                savedCallback.current(params);
            }
        };

        if (intervalState) {
            const id = setInterval(interval, delay);

            return () => {
                clearInterval(id);
            };
        }
    }, [delay, intervalState, params]);

    return toggleDelay;
};

export default useInterval;
