export const CustomNav = [
    {
        id: 'dashboard',
        icon: 'icon-newtab',
        label: 'Dashboard',
        content: [
            {
                label: 'Dashboard',
                to: '#/dashboard',
            },
        ],
    },
];

export const SettingsNav = [
    {
        id: 'wmsConnection',
        icon: 'icon-setting',
        label: 'Move to WMS',
        to: '',
    },
];
