import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useAppSelector, useTranslation } from '@hooks';

import RemainerCard from './RemainerCard';

import type { DashboardRes } from '../types';

interface Props {
    workers: DashboardRes['workers'];
}

// 픽셀 단위로 정수형으로만 가능함
const SCROLL_SPEED = 1;

const RemainerList: FC<Props> = ({ workers }) => {
    const t = useTranslation('Remainer');
    const { fullScreen } = useAppSelector(state => state.DashboardFrame);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = containerRef.current;
        let scrollAmount = 0;
        // 1은 down -1은 up
        let direction = 1;

        const scroll = () => {
            if (!container) return;

            container.scrollTop += direction * SCROLL_SPEED;

            if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
                direction = -1;
            }

            if (container.scrollTop <= 0) {
                direction = 1;
            }

            scrollAmount = requestAnimationFrame(scroll);
        };

        if (!fullScreen) return;
        scrollAmount = requestAnimationFrame(scroll);

        return () => cancelAnimationFrame(scrollAmount);
    }, [fullScreen]);

    return (
        <RemainerListContainer>
            <div className="header">
                <div className="title">{t('List of Remainers')}</div>
                <div className="d-flex gap-1 align-center">
                    <div className="current">{workers.length || 0}</div>
                    <div className="people">{t('People')}</div>
                </div>
            </div>
            <div className="body" ref={containerRef}>
                {workers.map(({ targetNum, targetName, phoneNumber, team, sosOn, lowBatteryOn }) => {
                    return (
                        <RemainerCard
                            key={targetNum}
                            name={targetName}
                            phoneNum={phoneNumber}
                            affiliation={team}
                            isSosOn={sosOn}
                            isBatteryOff={lowBatteryOn}
                        />
                    );
                })}
            </div>
        </RemainerListContainer>
    );
};

const RemainerListContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;

    width: 18.75rem;
    border-radius: 0.125rem;
    box-shadow: 0 7px 16px 0 rgba(24, 30, 111, 0.05);

    .header {
        display: flex;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;
        justify-content: space-between;

        border-bottom: 1px solid #e7e7eb;

        .title {
            color: #565672;
            font-size: 0.95rem;
            font-weight: 700;
        }

        .current {
            color: #51443b !important;
            font-size: 1.5rem;
            font-weight: 700;
        }

        .total {
            color: #cbc5bb !important;
            font-size: 1.5rem;
            font-weight: 700;
        }

        .people {
            color: #796d61 !important;
            font-size: 1rem;
            font-weight: 500;
        }
    }

    .body {
        height: calc(100% - 2.5rem);
        background-color: #e7e7eb;

        overflow: auto;
    }
`;

export default RemainerList;
