import React, { FC } from 'react';
import { divIcon } from 'leaflet';
//@ts-ignore
import { Marker } from 'react-leaflet';
import cx from 'classnames';

import type { Tag } from '../types';

interface Props {
    latlng: number[];
    target: Tag['target'];
    state: Tag['state'];
}

const SOS_PULSE = 'red-pulse';

const makeImgStr = (state: Tag['state'], targetName: string) => {
    const { sos } = state;

    const inLineStyle = cx(
        `style="background-color: black; color: black; border: 0.2rem solid #fff; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"`,
    );

    return `<div class="${cx('marker-img replace-img circle', sos && SOS_PULSE)}">
                            <div class="font-weight-bolder font-size-sm text-center" ${inLineStyle}>
                            </div>
                        </div>
                        <div class="marker-label">${targetName}</div>`;
};

const CustomMarker: FC<Props> = ({ latlng, target, state }) => {
    const { targetName } = target;

    const imgStr = makeImgStr(state, targetName);

    const icon = divIcon({
        className: 'category-marker',
        html: `${imgStr}`,
    });

    return <Marker position={latlng} icon={icon} />;
};

export default CustomMarker;
