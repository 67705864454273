import EVT_TYPE from '@util/staticData/eventType';

export const CHANGED_EVENT_TYPE: Partial<{ [key in keyof typeof EVT_TYPE]: string }> = {
    SOS_ON: 'Emergency Situation (SOS)',
    SOS_OFF: 'Emergency Situation (SOS) Release',
};

export const changeEventType = (eventType: keyof typeof EVT_TYPE) => {
    return CHANGED_EVENT_TYPE[eventType] || eventType;
};
