import React, { useCallback, useEffect, useMemo, useRef, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { useBlockLayout, usePagination, useTable } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
import cx from 'classnames';

import { useTranslation } from '@hooks';
import { Button, LoadingBlock } from '@components';
import {
    Column,
    FormatTitleProps,
    Row,
    RowPropGetterType,
    TableDataType,
    TableInstance,
    TextAlignType,
    UseTableOptions,
} from './types';
import NoDataBlock from '../../Widgets/Components/NoDataBlock';
import useTableLoading from './useTableLoading';

// 기존 36 -> 31 변경 ((line-height : 14 + padding : 8 + border : 1 ) => 31 로 계산되어 31로 변경)
const DEFAULT_TH_ROW_HEIGHT = 31;
// 기존 38 -> 35 변경 (DynamicRowHeight 에서는 38이었으나, 기존 테이블에서 35로 사용하여 35로 변경)
const DEFAULT_ROW_HEIGHT = 35;

const defaultRowPropGetter = <D extends object = {}>({ style }: RowPropGetterType<D>): RowPropGetterType<D> => {
    return { style: { ...style, height: DEFAULT_ROW_HEIGHT } };
};

// 테이블 너비 형식 변경
export const widthFormatter = (width?: number | string) => {
    return typeof width === 'string' ? width.replace('px', '').replace('%', '') : width;
};

interface MakeTitleProps<D extends object = {}, V = any> {
    className?: string;
    formatTitle?: ({ value, original }: FormatTitleProps<D, V>) => string | React.ReactNode;
    value?: V;
    original?: D;
}

// table title 속성 적용
export const makeTitle = <D extends object = {}>({ className, formatTitle, value, original }: MakeTitleProps<D>) => {
    if (typeof formatTitle === 'function') {
        return formatTitle({ value, original });
    }
    if (className && className.includes('text-ellipsis')) {
        return value;
    }
    return '';
};

// Table Component Props
export interface TableProps<D extends object = {}, V = any> {
    columns: Array<Column<D, V>>;
    data: TableDataType<D>;
    onPageChange?: (pageIndex: number) => void;
    paging?: boolean;
    textAlign?: TextAlignType;
    onTrClick?: (original: D, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    manualPagination?: boolean;
    autoResetPage?: boolean;
    getRowProps?: ({ row, style }: RowPropGetterType<D>) => RowPropGetterType<D>;
    loading?: boolean;
    className?: string;
    dynamicRowHeight?: boolean;
    noDataMessage?: string;
    actionButton?: ReactNode;
}

function Table<D extends object = {}>({
    columns,
    data: { rows: list, totalCount = 0, totalPage = 0, pageSize = 20, page: pageNum = 1, count = 0 },
    onPageChange,
    paging = true,
    textAlign = 'left',
    onTrClick,
    manualPagination = true,
    autoResetPage = true,
    getRowProps = defaultRowPropGetter,
    loading,
    className,
    dynamicRowHeight,
    noDataMessage,
    actionButton,
}: TableProps<D>) {
    const t = useTranslation('Search');
    const pageChanged = useRef(false);
    const defaultColumn = useMemo(() => ({ width: 150 }), []);
    const loadingObserver = useRef<HTMLDivElement>(null);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        totalColumnsWidth,
        prepareRow,
    } = useTable(
        {
            columns,
            data: list,
            initialState: { pageIndex: pageNum, pageSize },
            defaultColumn,
            manualPagination: manualPagination,
            pageCount: totalPage,
            autoResetPage: autoResetPage,
            // state를 직접 컨토를하기 위해 사용
            // API를 호출하여 리스트의 pageNum을 새롭게 받아왓을 때마다 테이블 내의 pageIndex를 업데이트
            useControlledState: state => {
                useMemo(() => {
                    state.pageIndex = pageNum - 1;
                }, [pageNum]);
                return state;
            },
        } as UseTableOptions<D>,
        usePagination,
        useBlockLayout,
    ) as TableInstance<D>;

    const tableLoading = useTableLoading({
        tableRef: loadingObserver,
        list,
        pageNum,
        totalPage,
        paging,
        isLoading: loading,
    });

    const RenderRow = useCallback(
        ({ row, style }: { row: Row<D>; style: React.CSSProperties }) => {
            prepareRow(row);
            const { className: rowClassName, ...restRowProps } = getRowProps({ row, style });

            if (dynamicRowHeight && restRowProps.style) {
                restRowProps.style.height = 'fit-content';
            }
            return (
                <div
                    {...row.getRowProps(restRowProps)}
                    className={cx('tr', typeof onTrClick === 'function' && 'clickable', rowClassName)}
                    onClick={e => {
                        if (typeof onTrClick === 'function') {
                            onTrClick(row.original, e);
                        }
                    }}
                >
                    {row.cells.map(cell => {
                        const { style: cellStyle, ...cellProps } = cell.getCellProps({
                            style: cell.column.style,
                        });
                        const {
                            column: { className, formatTitle },
                            row: { original },
                        } = cell;
                        return (
                            <div
                                {...cellProps}
                                className={cx('td', className)}
                                style={{
                                    ...cellStyle,
                                    flex: `${widthFormatter(cellStyle?.width)} 0 auto`,
                                }}
                                title={makeTitle({ className, formatTitle, value: cell.value, original })}
                            >
                                {cell.render('Cell')}
                            </div>
                        );
                    })}
                </div>
            );
        },
        [prepareRow, page],
    );

    useEffect(() => {
        // pageIndex값이 변하더라도 pageIndex가 변한 이유가 페이지 체인지 때문인지를 확인
        // 테이블의 리셋 버튼을 클릭했을 때는 onPageChange가 실행되는 것을 방지
        if (typeof onPageChange === 'function' && pageChanged.current) {
            pageChanged.current = false;
            onPageChange(pageIndex + 1);
        }
    }, [pageIndex]);

    const handlePageChange = (callback: (callbackParam?: number) => void, callbackParam?: number) => {
        // 페이지 체인지인 경우에는 current값을 true로 설정
        pageChanged.current = true;
        callback(callbackParam);
    };

    const pageStart = pageIndex * pageSize + (page.length ? 1 : 0);
    const pageEnd = totalCount < (pageIndex + 1) * pageSize ? totalCount : (pageIndex + 1) * pageSize;

    const keepValue = useMemo(() => {
        return {
            pageStart,
            pageEnd,
            totalCount,
        };
    }, [tableLoading]);
    const pagination = () => {
        if (loading === undefined) {
            return (
                <>
                    <span className="now">{`${pageStart}-${pageEnd}`}</span>
                    <span className="whole">{` / ${totalCount}`}</span>
                </>
            );
        }
        return (
            <>
                <span className="now">{`${keepValue.pageStart}-${keepValue.pageEnd}`}</span>
                <span className="whole">{` / ${keepValue.totalCount}`}</span>
            </>
        );
    };

    return (
        <LoadingBlock blocking={tableLoading}>
            <TableWrapper className={'flx-col'} textAlign={textAlign}>
                <div className="pagination justify-content-between" ref={loadingObserver}>
                    <div className="d-flex gap-3 align-items-center">
                        <Trans
                            t={t}
                            // 실시간 데이터는 count 로 이외는 totalCount로 내려옴
                            values={{ totalCount: count || totalCount }}
                            components={[
                                <span key="trans" className="font-size-md">
                                    <strong key="strong" />
                                </span>,
                            ]}
                        >
                            {'<0>Total Number Of Search Results : <0>{{totalCount}}</0></0>'}
                        </Trans>
                        {actionButton}
                    </div>
                    {paging && (
                        <div className="flx-row gap-1">
                            <div className="d-flex mx-2 justify-content-between">
                                <div className="now">{pagination()}</div>
                            </div>
                            <Button
                                className="btn-icon-only btn-trans form-h-small"
                                iconName="keyboard_double_arrow_left"
                                onClick={() => handlePageChange(gotoPage, 0)}
                            />
                            <Button
                                className="btn-icon-only btn-trans form-h-small"
                                iconName="keyboard_arrow_left"
                                onClick={() => handlePageChange(previousPage)}
                            />
                            <Button
                                className="btn-icon-only btn-trans form-h-small"
                                iconName="keyboard_arrow_right"
                                onClick={() => handlePageChange(nextPage)}
                            />
                            <Button
                                className="btn-icon-only btn-trans form-h-small"
                                iconName="keyboard_double_arrow_right"
                                onClick={() => handlePageChange(gotoPage, totalPage - 1)}
                            />
                        </div>
                    )}
                </div>
                <div
                    {...getTableProps()}
                    className={cx('pnt-table', 'table-clickable', className)}
                    style={{
                        height: paging ? 'calc(100% - 30px)' : '100%',
                        width: '100%',
                        color: '#585252',
                        fontSize: '.8rem',
                    }}
                >
                    <div className={'border-box'} style={{ overflow: 'auto hidden' }}>
                        <div className={'thead'} style={{ overflow: 'visible' }}>
                            {headerGroups.map((headerGroup, headerRowIdx: number) => {
                                const { style: thGroupStyle, ...thGroupProps } = headerGroup.getHeaderGroupProps();
                                return (
                                    <div {...thGroupProps} className="tr" style={{ ...thGroupStyle, width: '100%' }}>
                                        {headerGroup.headers.map(column => {
                                            const {
                                                depth,
                                                getHeaderProps,
                                                headerStyle,
                                                headerClassName,
                                                render,
                                                parent,
                                            } = column;
                                            const realisticallyUsingHeader = typeof depth === 'number';
                                            const rowSpanSize = !!parent ? 1 : headerRowIdx + 1;
                                            const noNeedMerge = !realisticallyUsingHeader || rowSpanSize === 1;
                                            const { style: thStyle, ...thProps } = getHeaderProps({
                                                style: headerStyle,
                                            });
                                            if (!noNeedMerge && thStyle) {
                                                thStyle.position = 'relative';
                                                thStyle.padding = 0;
                                                thStyle.overflow = 'visible';
                                                thStyle.borderBottom = 0;
                                            }
                                            return (
                                                <div
                                                    {...thProps}
                                                    className={cx(noNeedMerge && 'th', noNeedMerge && headerClassName)}
                                                    style={{
                                                        ...thStyle,
                                                        flex: `${widthFormatter(thStyle?.width)} 0 auto`,
                                                    }}
                                                >
                                                    {noNeedMerge ? (
                                                        render('Header')
                                                    ) : (
                                                        <RowSpanHeader
                                                            className={cx('th', headerClassName)}
                                                            style={{
                                                                height: `${rowSpanSize * DEFAULT_TH_ROW_HEIGHT}px`,
                                                            }}
                                                        >
                                                            {render('Header')}
                                                        </RowSpanHeader>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            {...getTableBodyProps()}
                            className={'tbody'}
                            style={{
                                height: `calc(100% - ${headerGroups.length * DEFAULT_TH_ROW_HEIGHT}px)`,
                                overflow: `${page.length > 0 ? 'visible' : 'hidden'}`,
                            }}
                        >
                            {page.length > 0 ? (
                                <AutoSizer>
                                    {({ height, width }: { height: number; width: number }) => {
                                        return (
                                            <div
                                                style={{
                                                    height,
                                                    width: width > totalColumnsWidth ? width : totalColumnsWidth,
                                                    overflow: 'hidden overlay',
                                                }}
                                            >
                                                {page.map(row => RenderRow({ row, style: { width } }))}
                                            </div>
                                        );
                                    }}
                                </AutoSizer>
                            ) : (
                                <NoDataBlock text={noDataMessage} />
                            )}
                        </div>
                    </div>
                </div>
            </TableWrapper>
        </LoadingBlock>
    );
}

export const TableWrapper = styled.div<{ textAlign: TextAlignType }>`
    height: 100%;
    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
    }
    .pnt-table {
        display: inline-block;
        border-spacing: 0;
        .border-box {
            height: 100%;
            border-top: 2px solid black;
            border-bottom: 2px solid black;

            .tbody {
                .tr {
                    :nth-child(2n) {
                        background-color: rgba(0, 0, 0, 0.02);
                    }
                    :hover {
                        background-color: #ebf0fa;
                    }
                }
                .tr.clickable {
                    :hover {
                        cursor: pointer;
                    }
                }
            }
            .tr {
                :last-child {
                    .td {
                        border-bottom: 0;
                    }
                }
            }
            .th {
                font-weight: 700;
                background-color: #d8e0fe;
                text-align: ${props => props.textAlign};
            }
            .td {
                text-align: ${props => props.textAlign};
            }
            .th,
            .td {
                margin: 0;
                padding: 0.5rem;
                border-bottom: 1px solid #ebebeb;
            }
        }
    }
`;

const RowSpanHeader = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`;

export default Table;
