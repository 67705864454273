import React from 'react';
import { Button } from '@components';

import envKeys from '../../../../environment';
import { getLsUserInfo } from '@util/common/util';
import { createSSOForm } from '../../AppNav/CustomLink';

const { cryptoIv } = envKeys;

const SettingDot = () => {
    const handleClick = () => {
        if (!cryptoIv) return;

        const serviceCode = cryptoIv.split('_')[2];
        const userInfo = getLsUserInfo();

        if (userInfo) {
            createSSOForm(serviceCode, userInfo.oAuthInfo);
        }
    };

    return (
        <div className="fullscreen-box">
            <Button className="btn-icon-only btn-trans" iconName="settings" onClick={handleClick} />
        </div>
    );
};

export default SettingDot;
