import axios from 'axios';
import envKeys from '../environment';
import { checkResponseErr, getLsUserInfo } from '@util/common/util';
import { store } from '../index';
import { setError } from '@reducer/ErrorHandler';
import qs from 'qs';

axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

const { userInfoKey } = envKeys;

const URL_KEY_OAUTH = 'oAuthUrl';
const URL_KEY_WMS = 'wmsApiUrl';
export const ERROR_RESPONSE_RESULT = 'error';

export const getApiURL = async function () {
    return await fetch(`${process.env.PUBLIC_URL}${process.env.REACT_APP_API_CONFIG_PATH}`)
        .then(response => {
            return response.json();
        })
        .then(config => {
            return config;
        })
        .catch(error => {
            console.log({ error });
            return {};
        });
};

const apiUrl = getApiURL();

const createConfigHandler = apiURLKey => async request => {
    const baseURL = await apiUrl;
    if (baseURL && baseURL[apiURLKey]) {
        request.baseURL = baseURL[apiURLKey];
        if (apiURLKey === URL_KEY_OAUTH) {
            request.data = {
                grant_type: 'password',
                client_id: baseURL.clientId,
                client_secret: baseURL.clientSecret,
                ...request.data,
            };
        }
    }
    if (localStorage.getItem(userInfoKey)) {
        const { oAuthInfo, userInfo } = getLsUserInfo();
        if (oAuthInfo && oAuthInfo.access_token) {
            request.headers['UUID'] = oAuthInfo.scope;
            request.headers['Authorization'] = `${oAuthInfo.token_type} ${oAuthInfo.access_token}`;
        }
        if (userInfo && userInfo.companyInfo && userInfo.companyInfo.uuid) {
            request.headers['UUID'] = userInfo.companyInfo.uuid;
            request.headers['token'] = userInfo.companyInfo.secret;
        }
    }
    if (baseURL.companyUUID && baseURL.companyToken) {
        request.headers['UUID'] = baseURL.companyUUID;
        request.headers['token'] = baseURL.companyToken;
    }
    return request;
};

const errHandler = error => {
    return Promise.reject(error);
};

const errorResponseHandler = response => {
    if (checkResponseErr(response)) {
        store.dispatch(setError(response));
    }
    return response;
};

const errorRejectHandler = error => {
    let { response } = error;
    let errMsg;

    if (!response) {
        const message = error.message ?? 'Unknown message.';
        response = { data: { result: ERROR_RESPONSE_RESULT, message } };
    }

    if (checkResponseErr(response) && response.data.message) {
        errMsg = response;
    } else {
        errMsg = {
            ...response,
            data: { ...response.data, message: 'The request failed.' },
        };
    }
    store.dispatch(setError(errMsg));

    return Promise.reject(error);
};

const wmsAxios = axios.create({
    baseURL: process.env.REACT_APP_WMS_API_URL,
});
wmsAxios.interceptors.request.use(createConfigHandler(URL_KEY_WMS), errHandler);
wmsAxios.interceptors.response.use(errorResponseHandler, errorRejectHandler);
// GET 호출에 배열 parameter 요청값을 보내기 위한 params 직렬화
wmsAxios.defaults.paramsSerializer = paramObj => qs.stringify(paramObj, { allowDots: true, skipNulls: true });

const oAuthAxios = axios.create({
    baseURL: process.env.REACT_APP_OAUTH_URL,
});
oAuthAxios.interceptors.request.use(createConfigHandler(URL_KEY_OAUTH), errHandler);
oAuthAxios.interceptors.response.use(errorResponseHandler, errorRejectHandler);

const fileAxios = axios.create({
    baseURL: process.env.REACT_APP_WMS_API_URL,
    headers: {
        'Content-Type': `multipart/form-data`,
    },
});
fileAxios.interceptors.request.use(createConfigHandler('wmsApiUrl'), errHandler);
fileAxios.interceptors.response.use(errorResponseHandler, errorRejectHandler);

export const reqGet = (url, param) => wmsAxios.get(url, { params: param });
export const reqPost = (url, param, reqConfig) => wmsAxios.post(url, param, { params: reqConfig });
export const reqFilePost = (url, param, reqConfig) => fileAxios.post(url, param, { params: reqConfig });
export const reqPut = (url, param) => wmsAxios.put(url, param);
export const reqPatch = (url, param) => wmsAxios.patch(url, param);
export const reqDelete = (url, param) => wmsAxios.delete(url, { params: param });
export const oAuthPost = (url, param) => oAuthAxios.post(url, param);

export default {
    reqGet,
    reqPost,
    reqPut,
    reqPatch,
    reqDelete,
    oAuthPost,
};
