import React from 'react';
import styled from 'styled-components';

const Label = ({ name, value }) => {
    const displayedValue = value ? value : '-';
    return (
        <LabelContainer>
            <LabelNameContainer title={name}>
                <LabelName>{name}</LabelName>
            </LabelNameContainer>
            <LabelValueContainer title={displayedValue}>
                <LabelValue>{displayedValue}</LabelValue>
            </LabelValueContainer>
        </LabelContainer>
    );
};

const LabelContainer = styled.div`
    display: flex;
    height: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    flex: 1;
    max-width: 50%;
    @media (max-width: 570px) {
        max-width: 100%;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LabelNameContainer = styled(FlexContainer)`
    width: 7.5rem;
    background-color: #f6f5f8;
`;

const LabelValueContainer = styled(FlexContainer)`
    width: calc(100% - 7.5rem);
`;

const TextEllipsis = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const LabelName = styled(TextEllipsis)`
    color: #203a60 !important;
    font-weight: bold;
    text-align: center;
    width: 100%;
`;

const LabelValue = styled(TextEllipsis)`
    color: #3f517f !important;
    font-weight: 600;
    padding: 0 1.25rem;
`;

export default Label;
