import React from 'react';
import Button from '@components/Button';
import { useRouteMatch } from 'react-router-dom';

const FullscreenDot = () => {
    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
            dashboardEl.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    return (
        <div className="fullscreen-box">
            <Button className="btn-icon-only btn-trans" iconName="fullscreen" onClick={toggleFullScreen} />
        </div>
    );
};

export default FullscreenDot;
