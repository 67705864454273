import React, { useState } from 'react';
import styled from 'styled-components';

import { useAsync, useInterval } from '@hooks';
import { getDashboardApi } from '@api/workManagement';

import RemainerHeader from './Components/RemainerHeader';
import RemainerMap from './Components/RemainerMap';
import RemainerList from './Components/RemainerList';

import type { DashboardRes } from './types';

const INTERVAL_TIME = 3 * 1000;

const INITIAL_DASHBOARD_DATA: DashboardRes = {
    dashboardCount: {
        remainingWorkerCount: 0,
        sosCount: 0,
        lowBatteryCount: 0,
    },
    workers: [],
};

const RemainersLocation = () => {
    const [dashboardData, setDashboardData] = useState<DashboardRes>(INITIAL_DASHBOARD_DATA);

    const { promise: getDashboard } = useAsync({
        promise: getDashboardApi,
        resolve: (res: DashboardRes) => {
            setDashboardData(res);
        },
        reject: (err: Error) => {
            console.error(err);
        },
        immediate: true,
    });

    useInterval({
        callback: getDashboard,
        delay: INTERVAL_TIME,
    });

    const { dashboardCount, workers } = dashboardData;

    return (
        <RemainersLocationContainer>
            <InformationContainer>
                <RemainerHeader dashboardCount={dashboardCount} />
                <RemainerMap />
            </InformationContainer>
            <RemainerList workers={workers} />
        </RemainersLocationContainer>
    );
};

const RemainersLocationContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    height: 100%;
    width: 100%;
`;

const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: calc(100% - 18.75rem);
`;

export default RemainersLocation;
