import React, { forwardRef, useRef, useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
import '@asset/main/map.scss';
import { Map as LeafletMap, ZoomControl, FeatureGroup } from 'react-leaflet';
import 'leaflet-rotate';
import Control from 'react-leaflet-control';
import { GTileLayer } from '@components';
import ResizeDetector from 'react-resize-detector';
import L from 'leaflet';
import { useTranslation } from '@hooks';
import { defaultOptions } from '@components/Map/config';
import { useSelector } from 'react-redux';
import { initUUIDv4 } from '@util/common/util';
import '@components/Map/custom';

const CustomMap = (
    {
        tile = true,
        children,
        customLayers,
        onMoveend,
        onZoomstart,
        onZoomend,
        geofenceList,
        handleGeofenceClick,
        rotation,
        onLocationClick,
        ...restProps
    },
    ref,
) => {
    const t = useTranslation('Map');
    const mapRef = useRef();
    const resizeTO = useRef();
    const handleResize = () => {
        if (resizeTO.current) {
            clearTimeout(resizeTO.current);
            resizeTO.current = null;
        }
        resizeTO.current = setTimeout(() => {
            if (mapRef.current) {
                mapRef.current.leafletElement.invalidateSize();
            }
            resizeTO.current = null;
        }, 200);
    };
    const { lang, userInfo } = useSelector(state => state.UserInfo);
    const mapDefaultCenter = useMemo(() => {
        let center = defaultOptions.center;
        const { companyInfo } = userInfo;
        if (companyInfo && companyInfo.lat) {
            center = [companyInfo.lat, companyInfo.lng];
        }
        return center;
    }, [userInfo]);

    const mapRotation = useMemo(() => {
        // WMS와 회전 방향 일치 시키기 위해 - 추가
        return isNaN(rotation) ? 0 : -rotation;
    }, [rotation]);

    const forcedUpdateKey = useMemo(() => {
        return `map-${initUUIDv4()}`;
    }, [mapRotation, mapDefaultCenter]);

    return (
        <ResizeDetector handleWidth handleHeight onResize={handleResize}>
            <div
                style={{ width: '100%', height: '100%' }}
                onMouseDown={e => {
                    e.stopPropagation();
                }}
            >
                <LeafletMap
                    key={forcedUpdateKey}
                    {...defaultOptions}
                    bearing={mapRotation}
                    center={mapDefaultCenter}
                    onClick={e => {
                        // 위경도 확인용. 지우지 마세요.
                        console.log(e);
                    }}
                    onMoveEnd={onMoveend}
                    onZoomstart={onZoomstart}
                    onZoomend={onZoomend}
                    ref={reference => {
                        if (ref) {
                            ref.current = reference;
                        }
                        mapRef.current = reference;
                    }}
                    whenReady={({ target }) => {
                        L.DomEvent.disableScrollPropagation(target._controlContainer);
                    }}
                    style={{ backgroundColor: '#C6C6E7' }}
                    {...restProps}
                >
                    <FeatureGroup>
                        <ZoomControl
                            position="bottomright"
                            key={'zoom-control-' + lang}
                            zoomInText={'<span class="material-icons-round md-18 color-black">add</span>'}
                            zoomOutText={'<span class="material-icons-round md-18 color-black">remove</span>'}
                            zoomInTitle={t('Zoom in')}
                            zoomOutTitle={t('Zoom out')}
                        />
                        <Control
                            key={'aim-control-' + lang}
                            position="bottomright"
                            className="leaflet-bar custom-leaflet-control"
                        >
                            <a
                                href={'#;'}
                                role="button"
                                className="flex-center"
                                onClick={e => {
                                    e.preventDefault();
                                    if (typeof onLocationClick === 'function') {
                                        onLocationClick();
                                        return;
                                    }
                                    const map = mapRef.current.leafletElement;
                                    if (map) {
                                        if (map.hasLayer(L.ImageOverlay)) {
                                            map.eachLayer(layer => {
                                                if (layer instanceof L.ImageOverlay) {
                                                    map.fitBounds(layer.getBounds());
                                                    return false;
                                                }
                                            });
                                        } else {
                                            map.eachLayer(layer => {
                                                if (layer instanceof L.Marker) {
                                                    map.setView([layer._latlng.lat, layer._latlng.lng]);
                                                    return false;
                                                }
                                            });
                                        }
                                    }
                                }}
                            >
                                <span className="material-icons-round md-18 color-black">location_searching</span>
                            </a>
                        </Control>
                    </FeatureGroup>
                    {tile && <GTileLayer />}
                    {children}
                </LeafletMap>
            </div>
        </ResizeDetector>
    );
};

export default forwardRef(CustomMap);
