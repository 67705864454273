import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Nullable, UnixTimestamp } from '@util/type/util';
import { OptionType } from '@components/type';

export const COMMAND_TYPE = {
    STANDBY: 'STANDBY',
    DRILL: 'DRILL',
    ACTUAL: 'ACTUAL',
} as const;

const VIEW_POINT = {
    STANDBY: 'STANDBY',
    INITIATE: 'INITIATE',
};

export type StatusType = typeof COMMAND_TYPE[keyof typeof COMMAND_TYPE];

export interface StandByAccount {
    commandType: StatusType;
    evacuationNum: Nullable<number>;
    initiatedDate: Nullable<UnixTimestamp>;
    totalTarget?: Nullable<string>;
    floorIds?: Nullable<string[]>;
    comNum?: number;
    totalPersonnelHeads?: Nullable<number>;
    viewPoint?: typeof VIEW_POINT[keyof typeof VIEW_POINT];
}

interface EnterReason {
    comNum: number;
    depth: number;
    enterReasonCode: string;
    enterReasonCodePath: string;
    enterReasonName: string;
    enterReasonNamePath: string;
    modDate: null;
    parentCode: string;
    regDate: UnixTimestamp;
    regNum: number;
    serviceCode: null;
}

interface InitialState {
    isIssuanceOpen: boolean;
    standByAccount: StandByAccount;
    enterReasons: EnterReason[];
    enterReasonOptions: OptionType[];
}

type FullScreenState = boolean | undefined;

const initialState: InitialState = {
    isIssuanceOpen: true,
    standByAccount: {
        commandType: COMMAND_TYPE.STANDBY,
        initiatedDate: null,
        evacuationNum: null,
        totalTarget: null,
        totalPersonnelHeads: null,
    },
    enterReasons: [],
    enterReasonOptions: [],
};

const { actions, reducer } = createSlice({
    name: 'workerSafety',
    initialState,
    reducers: {
        setStandByAccount: (state, action: PayloadAction<StandByAccount>) => {
            state.standByAccount = action.payload;
        },
        setToggleIssuance: (state, action: PayloadAction<FullScreenState>) => {
            const fullScreenState = action.payload;

            if (typeof fullScreenState === 'boolean') {
                state.isIssuanceOpen = fullScreenState;
                return;
            }
            state.isIssuanceOpen = !state.isIssuanceOpen;
        },
        setEnterReason: (state, action: PayloadAction<EnterReason[]>) => {
            state.enterReasons = action.payload;
            state.enterReasonOptions = action.payload.map(({ enterReasonName, enterReasonCode }) => ({
                label: enterReasonName,
                value: enterReasonCode,
            }));
        },
    },
});

export const { setStandByAccount, setToggleIssuance, setEnterReason } = actions;
export default reducer;
