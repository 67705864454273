import React, { FC } from 'react';
import styled from 'styled-components';

import { useTranslation } from '@hooks';

import AlertBadge from './AlertBadge';

import WORKER_OUTLINE_URL from '../../../../../assets/images/worker-safety/worker_outline.svg';
import SOS_URL from '../../../../../assets/images/worker-safety/sos.svg';
import LOW_BATTERY_URL from '../../../../../assets/images/worker-safety/low_battery.svg';

import type { DashboardRes } from '../types';

interface Props {
    dashboardCount: DashboardRes['dashboardCount'];
}

const RemainerHeader: FC<Props> = ({ dashboardCount }) => {
    const t = useTranslation('Remainer');

    const { remainingWorkerCount, sosCount, lowBatteryCount } = dashboardCount;

    return (
        <RemainerHeaderContainer>
            <Description>{t('This is the status of the vertical sphere.')}</Description>
            <AlertBadgeContainer>
                <AlertBadge
                    iconUrl={WORKER_OUTLINE_URL}
                    label={t('Remainer of vertical sphere')}
                    count={remainingWorkerCount}
                />
                <AlertBadge iconUrl={SOS_URL} label={t('SOS Signal')} count={sosCount} />
                <AlertBadge iconUrl={LOW_BATTERY_URL} label={t('IoT sensor low battery')} count={lowBatteryCount} />
            </AlertBadgeContainer>
        </RemainerHeaderContainer>
    );
};

const RemainerHeaderContainer = styled.div`
    display: flex;
    height: 7.5rem;
    padding: 1.25rem;
    align-self: stretch;
    align-items: center;
    justify-content: space-between;

    background-color: white;
`;

const Description = styled.div`
    font-size: 2rem;
    font-weight: 600;
`;

const AlertBadgeContainer = styled.div`
    display: flex;
    width: calc(100% - 24rem);
    justify-content: space-evenly;
`;

export default RemainerHeader;
