import moment from 'moment';

export const makeDateTime = unixStamp => {
    return !!unixStamp ? moment.unix(unixStamp).format('LLL') : '-';
};

export const makeDate = unixStamp => {
    return !!unixStamp ? moment.unix(unixStamp).format('LL') : '-';
};

export const makeDateWithBracket = unixStamp => {
    return !!unixStamp ? moment.unix(unixStamp).format('YYYY.MM.DD (HH:mm:ss)') : '-';
};

export const makeDateWithoutTime = unixStamp => {
    return !!unixStamp ? moment.unix(unixStamp).format('YYYY.MM.DD') : '-';
};

export const falsyToDash = value => {
    return !!value ? value : '-';
};

export const numOrDash = value => {
    return typeof value === 'number' ? value : '-';
};

export const typeOrDash = (value, type) => {
    return typeof value === type ? value : '-';
};

export const removeFalsyValue = obj => {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value));
};
